<template>
  <div>
    <el-dialog
      title="创建商机"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="100px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="客户姓名">
              <el-input v-model="form.contacts"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话" prop="telephone">
              <el-input v-model="form.telephone" @blur="phone2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系微信">
              <el-input v-model="form.weChat"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="性别">
          <el-radio-group v-model="form.sex">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
          <el-tag style="margin-left: 20px" effect="dark" type="info">{{
            isVip == 1 ? "会员" : "非会员"
          }}</el-tag>
          <el-tag style="margin-left: 20px" effect="dark" type="danger">{{
            newOrOldUser == 0 ? "新用户" : "老用户"
          }}</el-tag>
        </el-form-item>

        <el-form-item label="客户地址">
          <el-select
            v-model="form.province_id"
            placeholder="选择省"
            @change="choseplace(form.province_id, 1)"
            class="mr10 mt10 quyu"
          >
            <el-option
              v-for="(item, index) in place1"
              :key="index"
              :label="item.province"
              :value="item.province_id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="form.city_id"
            placeholder="选择市"
            @change="choseplace(form.city_id, 2)"
            class="mr10 mt10 quyu"
          >
            <el-option
              v-for="(item, index) in place2"
              :key="index"
              :label="item.city"
              :value="item.city_id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="form.county_id"
            placeholder="选择区/县"
            @change="choseplace(form.county_id, 3)"
            class="mr10 mt10 quyu"
          >
            <el-option
              v-for="(item, index) in place3"
              :key="index"
              :label="item.county"
              :value="item.county_id"
            >
            </el-option>
          </el-select>
          <!-- <div style="width: 460px">
            <el-cascader
              style="width: 100%"
              :options="options"
              v-model="selectedOptions"
              @change="addressChoose"
            ></el-cascader>
          </div> -->
        </el-form-item>
        <!-- 详细地址 -->
        <el-form-item label="详细地址">
          <el-input v-model="form.addres"></el-input>
        </el-form-item>

        <el-form-item label="商机来源">
          <el-row>
            <el-col :span="6">
              <el-select v-model="form.channel_id" placeholder="">
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="(item, index) in channelList"
                  :key="index"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col
              :span="6"
              :offset="1"
              v-if="secondLevelChannelList.length > 0"
            >
              <el-select v-model="form.channel_details" placeholder="">
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="(item, index) in secondLevelChannelList"
                  :key="index"
                ></el-option>
                <!-- <el-option label="京东" value="beijing"></el-option>  -->
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="问题标签" prop="pro_id">
          <!-- <el-checkbox-group v-model="form.pro_id">
            <el-checkbox
              :label="item.id"
              v-for="(item, index) in problemList"
              :key="index"
              >{{ item.title }}</el-checkbox
            >
          </el-checkbox-group> -->
          <el-radio-group v-model="form.pro_id">
            <el-radio
              :label="item.id"
              v-for="(item, index) in problemList"
              :key="index"
              >{{ item.title }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="商机类型" prop="dispatchType">
          <el-radio-group v-model="form.dispatchType">
            <el-radio label="1">有效商机</el-radio>
            <el-radio label="2">一般商机</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="k !== 1"
          label="预计跟进时间"
          prop="constructionTime"
          style="height: 80px"
        >
          <el-radio-group v-model="form.constructionTime">
            <el-row style="margin-top: 13px">
              <el-radio label="1">按分钟</el-radio>
              <el-radio label="2">按天</el-radio>
              <el-radio label="3">自定义时间</el-radio>
            </el-row>
          </el-radio-group>
          <el-radio-group
            v-model="form.specifiedTime"
            v-if="form.constructionTime == '1' || form.constructionTime == '2'"
          >
            <div style="margin-top: 20px">
              <el-radio
                :label="item"
                v-for="(item, index) in form.constructionTime == '1'
                  ? times1
                  : times2"
                :key="index"
                >{{
                  form.constructionTime == "1"
                    ? item + "分钟"
                    : "第" + item + "天"
                }}</el-radio
              >typek
            </div>
          </el-radio-group>
          <div v-else>
            <el-date-picker
              v-model="form.specifiedTime"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="备注">
          <el-input type="textarea" v-model="form.remarks"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit('form')"
            >保存商机</el-button
          >
          <el-button @click="assign">指派订单</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <PoolToAsign :poolOrderInfo="form" ref="PoolToAsign"></PoolToAsign>
  </div>
</template>
<script>
import { regionData } from "element-china-area-data";
export default {
  components: {
    PoolToAsign: () => import("@/components/PoolToAsign.vue"),
  },
  name: "AddClueOrder",
  props: ["order_pool_id"],
  data() {
    return {
      // 省市区级联
      options: regionData,
      selectedOptions: [],
      PoolToAsignDialog: false,
      problemList: [], // 问题数组
      // 跟进时间数组
      //   times1: ["5分钟", "10分钟", "20分钟", "30分钟", "60分钟"], //按分钟
      //   times2: ["第二天", "第三天", "第五天", "第七天", "第十天"], //按天
      times1: ["5", "10", "20", "30", "60"], // 按分钟
      times2: ["2", "3", "5", "7", "10"], // 按天
      dialogVisible: false,
      form: {
        addres: "", // 详细地址
        contacts: "", // 客户姓名
        telephone: "", // 联系电话
        weChat: "", // 联系微信
        sex: "", // 性别
        province_id: "", // 省
        city_id: "", // 市
        county_id: "", // 区
        channel_id: "", // 一级渠道
        channel_details: "", // 二级渠道
        pro_id: "", // 问题标签
        dispatchType: "", // 商机类型
        constructionTime: "1", // 预计时间
        remarks: "", // 备注
        specifiedTime: "", // 自定义时间（时间戳）
        order_pool_id: "",
      },
      place1: [],
      place2: [],
      place3: [],

      oneplace: 1,
      twoplace: 241,
      threeplace: 2242,
      detailedplace: "",
      House: "",
      diandianid: "",
      details: {
        province_id: "",
        city_id: "",
        county_id: "",
      },
      //   rules: {
      //     pro_id: [{ required: true, message: "请选择问题", trigger: "change" }],
      //     dispatchType: [
      //       { required: true, message: "请选择商机类型", trigger: "change" },
      //     ],
      //     constructionTime: [
      //       { required: true, message: "请选择跟进时间", trigger: "change" },
      //     ],
      //   },

      old_id: 1, // 当前省份的old_id
      cityOld_id: 241, // 当前城市的old_id
      countyOld_id: 2242,
      channelList: [], // 一级渠道列表
      secondLevelChannelList: [], // 二级渠道列表
      type: 1, // 1:add2:edit
      k: 0, // 0 默认是创建商机  1是编辑商机
      diandianid: "",
      newOrOldUser: 0, // 新老用户
      isVip: 0, // 是否会员
      newForm: {},
    };
  },
  watch: {
    "form.channel_id": {
      handler(newVal, oldVal) {
        this.channelList.forEach((item) => {
          if (item.id == newVal) {
            this.secondLevelChannelList = item.data;
            if (this.secondLevelChannelList.length > 0) {
              this.form.channel_details =
                this.secondLevelChannelList[0].id || "";
            }
          }
        });
      },
      //   immediate: true,
      deep: true, // 可以深度检测到 person 对象的属性值的变化
    },
  },
  computed: {
    // PoolToAsignDialog() {
    //   return this.$store.state.poolToAsign;
    // },
  },
  created() {
    this.problemList = [];
    if (this.form.order_pool_id == "") {
      this.getNewProvince(0, 1);
    }
  },

  mounted() {},
  methods: {
    phone2() {
      if (this.phone != "") {
        this.util
          .get(this.HOST + "/Order/phone", {
            phone: this.form.telephone,
          })
          .then((res) => {
            if (res.code == 200) {
              if (res.data.count != 0) {
                this.newOrOldUser = 2;

                this.$notify({
                  title: "警告",
                  message: "此电话号码已存在",
                  offset: 100,
                  duration: 0,
                  type: "warning",
                });
              } else {
                this.newOrOldUser = 0;
              }
              this.isVip = res.data.isVip;
            }
          });
      }
    },

    getNewProvince(id, times, c) {
      this.util
        .get(this.HOST + "/Common/newProvince", {
          city_id: id,
          structures: times,
        })
        .then((res) => {
          if (times == 1) {
            this.place1 = res.data;
            if (c == 1) {
              this.form.province_id = res.data[0].province_id;
            }
            this.getNewProvince(this.form.province_id, 2);
          } else if (times == 2) {
            this.place2 = res.data;
            if (c == 1) {
              this.form.city_id = res.data[0].city_id;
            }
            this.getNewProvince(this.form.city_id, 3, 1);
          } else {
            this.place3 = res.data;
            if (c == 1) {
              let arr = [];
              this.place3.map((item) => {
                arr.push(item.county_id);
              });
              if (arr.indexOf(this.form.county_id) == -1) {
                this.form.county_id = res.data[0] && res.data[0].county_id;
              }
            }
          }
          this.getChanelList();
          this.getproblems();
        });
    },
    choseplace(id, times, hand) {
      if (times == 1) {
        // this.place1 = res.data;
        this.getNewProvince(this.form.province_id, 2, 1);
      } else if (times == 2) {
        // this.place2 = res.data;
        this.getNewProvince(this.form.city_id, 3, 1);
      }
    },
    assign() {
      // console.log(this.form.order_pool_id);
      if (this.form.order_pool_id == "") {
        this.$message({
          showClose: true,
          message: "请先保存再指派",
          type: "error",
          duration: 1200,
          offset: 90,
        });
      } else {
        this.handleClose();
        this.$store.state.poolToAsign = true;
        this.PoolToAsignDialog = true;
        this.$refs.PoolToAsign.handleShow(this.order_pool_id);
      }
    },
    cloosePoolToAsign() {
      this.PoolToAsignDialog = false;
    },

    myTimeToLocal(inputTime) {
      if (!inputTime && typeof inputTime !== "number") {
        return "";
      }
      var localTime = "";
      inputTime = new Date(inputTime).getTime();
      const offset = new Date().getTimezoneOffset();
      localTime = new Date(inputTime - offset * 60000).toISOString();
      localTime = localTime.substr(0, localTime.lastIndexOf("."));
      localTime = localTime.replace("T", " ");
      return localTime;
    },
    // 提交按钮
    onSubmit(formName) {
      //   this.$refs[formName].validate((valid) => {
      //     if (valid) {

      if (this.form.telephone == "" && this.form.weChat == "") {
        this.$message({
          showClose: true,
          message: "请填写电话或者微信",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return;
      }
      if (this.form.pro_id == "") {
        this.$message({
          showClose: true,
          message: "请选择问题标签",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return;
      }
      if (this.form.dispatchType == "") {
        this.$message({
          showClose: true,
          message: "请选择商机类型",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return;
      }
      if (this.form.constructionTime == "") {
        this.$message({
          showClose: true,
          message: "请选择预计跟进时间",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return;
      }
      if (this.form.specifiedTime == "") {
        this.$message({
          showClose: true,
          message: "请选择详细时间",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return;
      }

      this.util
        .post(
          this.HOST + (this.type == 1 ? "/OrderPool/add" : "/OrderPool/Edit"),
          {
            addres: this.form.addres, // 详细地址
            contacts: this.form.contacts, // 客户姓名
            telephone: this.form.telephone, // 联系电话
            weChat: this.form.weChat, // 联系微信
            sex: this.form.sex, // 性别
            province_id: this.form.province_id, // 省
            city_id: this.form.city_id, // 市
            county_id: this.form.county_id, // 区
            channel_id: this.form.channel_id, // 一级渠道
            channel_details:
              this.secondLevelChannelList.length == 0
                ? ""
                : this.form.channel_details, // 二级渠道
            pro_id: this.form.pro_id || "", // 问题标签, //问题标签
            dispatchType: this.form.dispatchType, // 商机类型
            constructionTime: this.form.constructionTime, // 预计时间
            remarks: this.form.remarks, // 备注
            specifiedTime:
              this.form.constructionTime == 3
                ? this.myTimeToLocal(this.form.specifiedTime)
                : this.form.specifiedTime, // 自定义时间（时间戳）
            order_pool_id: this.order_pool_id,
            //   isVip: this.isVip, //是否会员
            newOrOldUser: this.newOrOldUser, // 新老用户
          }
        )
        .then((res) => {
          // this.getNewProvince(0, 1);
          if (res.code == 200) {
            this.handleClose();
            Object.keys(this.form).map((key) => {
              this.form[key] = "";
            });
            this.$parent.getLists(0);
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 关闭弹窗
    handleClose(done) {
      this.k = 0;
      this.type = 1;
      this.dialogVisible = false;
      Object.keys(this.form).map((key) => {
        this.form[key] = "";
      });
    },
    // 打开弹窗
    showDialog() {
      this.dialogVisible = true;
    },

    // hand:操作的是哪一个

    // 获取渠道
    getChanelList() {
      console.log("省市区", this.form.province_id, this.form.city_id);

      if (this.form.city_id != "") {
        this.util
          .get(this.HOST + "/Common/chanel", {
            city_id: this.form.city_id,
            // //uu: this.form.province_id,
          })
          .then((res) => {
            if (res.code == 200) {
              this.channelList = res.data;
              // this.form.channel_id = this.channelList[0].id;
            }
          });
      }
    },
    // 获取问题标签
    getproblems() {
      this.problemList = [];
      if (this.form.city_id != "") {
        this.util
          .get(this.HOST + "/Common/programme", {
            city_id: this.form.city_id,
          })
          .then((res) => {
            if (res.code == 200) {
              this.problemList = res.data;
            }
          });
      }
    },
    // 请求订单详情用于编辑
    getInfo(e, k) {
      console.log("请求订单详情用于编辑", e, k);
      if (e) {
        this.order_pool_id = e;
      }
      if (k) {
        this.k = k;
      }
      this.type = 2;
      this.util
        .get(this.HOST + "/OrderPool/info", {
          orderPoolId: this.order_pool_id,
        })
        .then((res) => {
          if (res.code == 200) {
            // this.getplace(1, 1);
            this.newForm = res.data;
            this.form.addres = res.data.netAddres;
            this.form.contacts = res.data.contacts;
            this.form.telephone = res.data.telephone;
            this.form.weChat = res.data.weChat;
            this.form.sex = res.data.sex == "男" ? "1" : "2";
            // this.old_id = res.data.province_id;
            this.details.province_id = res.data.province_id;
            this.details.city_id = res.data.city_id;
            this.details.county_id = res.data.county_id;

            // this.cityOld_id = res.data.city_id;
            // this.countyOld_id = res.data.county_id;
            this.form.channel_id = res.data.channel_id;
            this.form.channel_details = res.data.channel_details;
            this.form.pro_id = res.data.pro_id;
            this.form.dispatchType = String(res.data.dispatchType);
            this.form.constructionTime = String(res.data.constructionTime);
            this.form.remarks = res.data.remarks;
            this.form.specifiedTime = String(res.data.specifiedTime);
            this.form.order_pool_id = res.data.order_pool_id;
            this.form.province_id = res.data.province_id;
            this.form.city_id = res.data.city_id;
            this.form.county_id = res.data.county_id;
            // this.isVip = res.data.isVip;
            this.newOrOldUser = res.data.newOrOldUser;
            this.getNewProvince(0, 1);
            this.showDialog();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
  },
};
</script>
